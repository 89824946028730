export const rangerFilterDetails = [
  {
    label: "Ranged Weapon",
    toggle: "isRanged",
    default: false,
    checked: false,
  },
  {
    label: "Colossal Slayer",
    toggle: "hasColossalSlayer",
    default: false,
    checked: false,
  },
  {
    label: "Sharpshooter",
    toggle: "hasSharpshooter",
    default: false,
    checked: false,
  },
  {
    label: "Extra Attack",
    toggle: "hasExtraAttack",
    default: true,
    checked: true,
  },
];

export const rogueFilterDetails = [
    {
      label: "Sneak Attack",
      toggle: "hasSneakAttack",
      default: true,
      checked: true,
    },
    {
      label: "Sharpshooter",
      toggle: "hasSharpshooter",
      default: false,
      checked: false,
    },
  ];
  