import React, { useEffect, useState } from "react";

import { screenHeight, screenWidth } from "../common/commonConstants";

import "./LandingPage.css";

const LandingPage = () => {
  const [svgFill, setSvgFill] = useState("#919191");
  const [svgStroke, setSvgStroke] = useState("#FFFFFF");
  useEffect(() => {
    setTimeout(() => {
      setSvgFill("#810404");
      setSvgStroke("#810404");
    }, 5000);
  }, []);
  return (
    <div
      style={{
        backgroundColor: "#919191",
        zIndex: -50,
        position: "absolute",
        top: "0px",
      }}
      id="d20-svg-container"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 864 864"
        style={{ height: screenHeight, width: screenWidth }}
      >
        <g>
          <path
            className="path"
            stroke={svgStroke}
            fill={svgFill}
            d="M308.6,371.6c-3.2-3.3-6.2-6.4-9.1-9.4c-1.7-1.7-3.4-3.4-5-5.1c-6.9-7.1-13.8-14.1-20.7-21.2c-0.6-0.6-1.2-1.2-1.7-1.9
		c-0.2-0.3-0.2-0.7-0.3-1.3c0.6-0.1,0.9-0.2,1.3-0.2c6.4,0.1,12.9,0.2,19.3,0.3c0.4,0,0.8,0.1,1.2,0c2.1-0.1,3.7,0.8,5.2,2.3
		c7.9,8.3,15.9,16.5,23.8,24.7c0.7,0.7,1.5,1.5,2.3,2.3c1.9-0.7,3.5-1.9,5.3-3c-0.8-0.9-1.5-1.7-2.2-2.4c-7.7-7.9-15.4-15.8-23-23.7
		c-0.6-0.6-1.1-1.2-1.7-1.8c-1.8-1.7-2.5-3.8-2.4-6.2c0.1-3.3,0.2-6.7,0.2-10c0-3,0.1-6,0.1-9c0-0.8,0.2-1.6,0.4-2.7
		c0.7,0.3,1.2,0.5,1.5,0.8c0.7,0.6,1.3,1.3,2,2c13.1,13.6,26.2,27.3,39.4,40.9c0.8,0.8,1.6,1.6,2.5,2.5c1.1-0.6,2.1-1.1,3.1-1.7
		c11.9-7,23.8-14,35.7-21c8.6-5,17.2-10,25.9-15c4.3-2.5,8.6-5,12.9-7.5c2.7-1.6,5.6-2.5,8.8-2.9c6-0.7,11.3,1,16.3,4
		c6.1,3.6,12.2,7.1,18.3,10.6c12.1,6.9,24.2,13.8,36.3,20.7c10,5.7,20,11.5,30,17.2c0.2,0.1,0.4,0.1,0.7,0.2
		c0.5-0.4,1.1-0.8,1.6-1.3c10.8-10.2,21.7-20.4,32.5-30.6c3.8-3.6,7.5-7.2,11.3-10.7c0.6-0.6,1.2-1.5,2.5-1.2
		c0.1,0.3,0.2,0.7,0.2,1.1c-0.2,7.1-0.5,14.1-0.6,21.2c0,2-0.9,3.3-2.3,4.6c-8.7,8.1-17.3,16.2-26,24.3c-0.7,0.7-1.5,1.4-2.2,2.2
		c0.7,1,1.7,1.3,2.5,1.9c0.8,0.5,1.6,0.9,2.6,1.4c0.8-0.8,1.7-1.5,2.5-2.3c7.7-7.3,15.4-14.5,23.2-21.8c0.6-0.6,1.2-1.1,1.8-1.7
		c1.3-1.2,2.8-1.8,4.6-1.7c2.3,0.1,4.6,0.1,6.9,0.2c4.2,0.1,8.3,0.3,12.5,0.4c0.8,0,1.7,0.1,2.5,0.2c0.5,0.1,0.8,0.8,0.4,1.2
		c-0.2,0.3-0.4,0.5-0.6,0.7c-0.6,0.6-1.2,1.1-1.8,1.7c-10.7,10-21.5,20-32.2,30c-0.8,0.8-1.6,1.6-2.6,2.5c2.6,1.9,5.1,3.4,7.7,4.8
		c1.6,0.9,3.3,1.7,4.9,2.7c5.9,3.6,9.5,8.9,10.7,15.7c0.2,1.3,0.3,2.7,0.3,4c0.1,11.1,0.3,22.3,0.2,33.4c-0.1,8.3-0.1,16.6,0.2,24.9
		c0.1,2.7-0.2,5.4-0.2,8.1c0.1,12.3,0.2,24.5,0.2,36.8c0,9.4-0.1,18.7-0.1,28.1c0,7.8,0.2,15.6,0.3,23.4c0,3-0.2,6-0.5,9
		c-0.7,6.1-3.6,10.8-8.4,14.3c-2.7,2-5.6,3.5-8.4,5.3c-0.9,0.6-2.1,0.9-2.9,2c0.7,0.8,1.4,1.6,2.1,2.3c9.9,10.2,19.8,20.4,29.8,30.7
		c4.1,4.2,8.2,8.2,12.2,12.5c6.8,7.3,13.9,14.3,20.3,22c7.7,9.3,14,19.4,18.6,30.5c2.8,6.7,4.6,13.6,5,20.8c0.1,1.1,0.3,2.3-0.5,3.4
		c-3.3,0.2-6.6-0.3-9.8-1c-6.7-1.5-13.1-4.1-19.2-7.3c-9.9-5.3-18.8-11.9-27.1-19.4c-6.7-6-12.7-12.6-18.7-19.2
		c-2.2-2.5-4.6-4.8-6.9-7.2c-13.8-14.2-27.5-28.4-41.3-42.5c-1-1-2-2.1-3-3.1c-1.3,0.2-2.2,1-3.2,1.5
		c-19.2,11.2-38.5,22.3-57.7,33.5c-8.6,5-17.2,10-25.9,15c-1.7,1-3.4,2-5.2,2.9c-7.5,3.9-15,3.9-22.3-0.2c-4.6-2.6-9.1-5.4-13.7-8
		c-6.1-3.5-12.3-7-18.4-10.5c-8.1-4.6-16.2-9.3-24.3-14c-3.7-2.1-7.4-4.2-11.1-6.3c-4.8-2.7-9.5-5.5-14.3-8.2
		c-2.2-1.2-4.3-2.5-6.5-3.7c-2.5-1.4-5.1-2.9-7.6-4.3c-0.7-0.4-1.4-0.8-2.3-1.3c-0.6,0.5-1.2,0.8-1.7,1.3
		c-9.2,8.6-18.4,17.2-27.5,25.8c-4.1,3.8-8.1,7.7-12.2,11.6c-2,1.9-4,3.9-6.1,5.8c-5.3,4.9-10.5,9.7-15.9,14.5
		c-3.6,3.2-7.1,6.4-10.8,9.4c-9.5,7.6-19.8,13.7-31.1,18.2c-6.5,2.6-13.3,4.1-20.3,4.4c-1.1,0.1-2.3,0.2-3.4-0.6
		c0-2.6,0.3-5.1,0.8-7.7c1.5-7.5,4.4-14.5,8.1-21.2c5.4-9.8,12.2-18.6,19.8-26.8c6-6.5,12.6-12.4,19.3-18.2c2.5-2.2,4.9-4.5,7.3-6.8
		c10.5-9.9,21-19.8,31.6-29.7c0.8-0.8,1.7-1.5,2.7-2.4c-0.9-0.7-1.5-1.1-2.1-1.6c-4.8-3.4-8.3-7.9-9.7-13.7
		c-0.4-1.5-0.6-3.1-0.6-4.6c-0.1-7.5,0-15,0-22.4c0-2.1-0.1-4.2-0.1-6.2c-0.1-6.3-0.1-12.7-0.1-19c0-16.1,0-32.2-0.1-48.3
		c0-12.7-0.1-25.4-0.1-38.1c0-8.5-0.1-17.1-0.2-25.6c-0.1-3.9,0.7-7.6,2.3-11.2c1.6-3.7,4-6.8,7.4-9c1.4-0.9,2.8-1.8,4.2-2.6
		c6-3.5,12.1-7,18.1-10.5C307.3,372.6,307.8,372.2,308.6,371.6z M436.9,544C436.9,544,436.9,544,436.9,544c3-0.1,6,0,9-0.1
		c6.4-0.1,12.9-0.2,19.3-0.2c5.7,0,11.4-0.4,17.1,0.2c1.3,0.1,2.7,0,4,0c5-0.1,10-0.2,15-0.3c1.6,0,3.1-0.1,4.7-0.3
		c2.1-0.3,3.5-1.5,4.4-3.5c0.9-2.2,0.9-4.2-0.3-6.2c-0.6-1.1-1.2-2.2-1.8-3.3c-3.7-6.4-7.4-12.8-11.1-19.1
		c-17.2-29.6-34.5-59.3-51.7-88.9c-1.1-1.9-2.2-3.8-3.4-5.6c-2.7-3.9-7.8-4.1-10.8-0.3c-0.8,1-1.4,2.1-2,3.2
		c-3.2,5.8-6.4,11.6-9.7,17.4c-3.8,6.6-7.6,13.1-11.4,19.7c-2.7,4.7-5.4,9.4-8,14.1c-2.5,4.3-4.8,8.7-7.3,13
		c-1.6,2.8-3.3,5.5-4.9,8.3c-2.2,3.8-4.3,7.6-6.5,11.4c-4.4,7.6-8.7,15.1-13.1,22.7c-1.6,2.8-3.1,5.7-4.6,8.5
		c-2,3.7,0.3,8.9,5.3,9.4c0.4,0,0.8,0,1.2,0c8.2,0,16.4,0.1,24.6,0.1c7,0,13.9-0.1,20.9-0.1C422.8,544,429.9,544,436.9,544z
		 M358.4,400.6c0-0.1,0-0.1,0-0.2c-8-0.2-16-0.5-24-0.4c-7.5,0-14.9-0.5-22.4-0.4c-4,0.1-8.1-0.2-12.2-0.3c-0.7,0-1.5,0-2.2,0
		c-1.8,0.1-3.1,1-4,2.6c-1,1.8-0.9,3.5-0.1,5.4c3.5,8.1,7,16.2,10.5,24.3c7.7,17.8,15.4,35.7,23.1,53.5c6,13.8,11.9,27.7,17.9,41.5
		c0.3,0.8,0.7,1.5,1,2.3c1.8,4.2,6.8,3.4,8.7,0.8c0.5-0.8,1-1.6,1.4-2.4c1.6-2.9,3.3-5.8,4.9-8.7c5.1-8.8,10.2-17.6,15.2-26.5
		c3.4-5.9,6.7-11.7,10-17.6c2-3.5,4-7.1,6-10.6c3.7-6.3,7.4-12.5,11.1-18.8c2.7-4.7,5.3-9.4,7.9-14.1c3.8-6.6,7.5-13.2,11.3-19.7
		c0.4-0.6,0.8-1.2,1.1-1.9c1.7-3.5-0.1-7.1-3.9-7.8c-0.8-0.2-1.7-0.2-2.5-0.2c-4.4,0-8.7,0-13.1-0.1c-9.4-0.2-18.7-0.1-28.1-0.7
		c-0.6,0-1.2,0-1.9,0C369,400.6,363.7,400.6,358.4,400.6z M514.9,401.8c0,0,0-0.1,0-0.1c-6.3,0-12.7,0-19,0
		c-12.5,0-24.9,0.1-37.4,0.2c-5.5,0-9.2,5.2-7.3,10.3c0.4,1.1,1,2.1,1.5,3.1c1.9,3.3,3.9,6.6,5.8,9.9c6.5,11.1,13,22.2,19.5,33.3
		c11.6,19.9,23.2,39.9,34.8,59.8c1.1,2,2.3,4,3.4,5.9c2.2,3.8,6.3,5,10.2,3.2c1.8-0.8,2.9-2.2,3.7-4c4-8.8,8-17.6,12-26.4
		c4.7-10.4,9.3-20.8,14-31.3c1.6-3.7,3.2-7.5,4.8-11.1c3.3-7.3,6.6-14.6,9.9-21.9c1-2.3,2.1-4.5,3.1-6.8c2.1-4.9,4.2-9.7,6.2-14.6
		c1.7-4-1.3-8.6-5.1-9.5c-1.4-0.3-2.9-0.4-4.3-0.5c-1.4-0.1-2.9,0.1-4.4,0.1c-5.7,0-11.4,0.1-17.1,0.1c-5.5,0-11,0.1-16.5,0.2
		c-2.1,0-4.2-0.2-6.2-0.2C522.6,401.6,518.8,401.7,514.9,401.8z M436.5,553.8c0,0,0,0.1,0,0.1c-3.8,0-7.7,0-11.5,0
		c-15.9,0-31.8,0.1-47.7,0.1c-2.4,0-4.8,0-7.2,0c-2.2,0-3.8,1.3-4.8,3.1c-0.9,1.7-0.7,3.6,0.5,5.3c0.5,0.7,1,1.3,1.5,2
		c7.1,9.2,14.3,18.4,21.4,27.6c4,5.2,7.9,10.5,11.9,15.6c8.8,11.4,17.7,22.8,26.6,34.2c2,2.6,4.2,5.2,6.2,7.8
		c1.4,1.8,3.1,2.2,5.3,1.9c1.5-0.2,2.3-1.3,3.1-2.4c3.2-4.1,6.3-8.2,9.5-12.4c9.2-12.1,18.3-24.2,27.5-36.3
		c5.2-6.9,10.4-13.8,15.6-20.6c4.2-5.6,8.4-11.1,12.6-16.6c0.4-0.6,0.9-1.1,1.2-1.8c1.3-2.6,0.5-6.4-2.9-7.3c-1.3-0.3-2.7-0.5-4-0.5
		c-8.8,0-17.7,0.1-26.5,0.1c-8.8,0.1-17.7,0.1-26.5,0.2C444.4,553.8,440.4,553.8,436.5,553.8z M441,349.2
		C441,349.2,441,349.2,441,349.2c0,2,0,3.9,0,5.9c0,2,0.2,3.9,0.2,5.9c0,6.1-0.1,12.3-0.1,18.4c0,1.3,0.1,2.7,0.5,4
		c0.7,2.9,2.2,5.2,5,6.8c2.2,1.2,4.4,1.8,6.8,1.7c4.9,0,9.8-0.1,14.7-0.1c14.2,0,28.5,0,42.7,0c5.5,0,11,0,16.5-0.1
		c3.6,0,7.3-0.1,10.9-0.1c7.6,0,15.2,0,22.8,0.1c4.6,0,9.1-0.4,13.7,0.2c1,0.1,2.1-0.1,3.1-0.3c0.6-0.1,1.2-0.5,1.2-1.2
		c0-0.6-0.3-1.2-0.6-1.7c-0.5-0.5-1.2-0.8-1.8-1.2c-5.9-3.3-11.8-6.6-17.7-9.9c-9.3-5.3-18.6-10.6-27.9-15.9
		c-11.4-6.6-22.9-13.1-34.3-19.7c-4.1-2.4-8.2-4.8-12.4-7.2c-1.7-1-3.5-1.9-5.2-2.8c-5.2-3-10.4-6.1-15.6-9.1
		c-4.8-2.8-9.7-5.6-14.6-8.4c-0.9-0.5-1.8-1-2.8-1.4c-3.1-1.2-4.3-0.4-4.9,2.9c-0.1,0.4-0.1,0.8-0.1,1.2c-0.1,1.7-0.1,3.3-0.1,5
		C441,331.3,441,340.2,441,349.2z M431.2,348.7c-0.1,0-0.1,0-0.2,0c0.1-6,0.3-12.1,0.4-18.1c0-0.6,0-1.2,0-1.9c0-2-0.1-3.9-0.1-5.9
		c-0.1-2.4,0.1-4.8-0.2-7.2c-0.3-3.2-2-4.1-4.6-2.5c-2.9,1.8-6,3.4-8.9,5.1c-15.3,8.9-30.5,17.9-45.8,26.7
		c-22.6,13.1-45.2,26.1-67.8,39.1c-1.2,0.7-2.3,1.4-3.4,2.1c-0.4,0.3-0.8,0.6-1.1,1.1c-0.6,0.8,0,2,1,2.1c1.1,0,2.3,0,3.4,0
		c13.4,0.3,26.8,0.6,40.2,0.8c5.8,0.1,11.6,0.2,17.5,0.4c10.7,0.2,21.4,0.4,32.1,0.6c6,0.1,12.1,0.3,18.1,0.4
		c2.6,0.1,5.2,0.1,7.8,0.1c5.9,0,9-2.6,10.8-6.9c0.7-1.8,0.9-3.6,0.9-5.5c0-2.4,0-4.8,0-7.2C431.2,364.3,431.2,356.5,431.2,348.7z
		 M284.1,411.5c-0.5,1-0.8,1.5-0.9,2.1c-0.1,0.9,0,1.9,0,2.8c0.1,6.4,0.3,12.9,0.3,19.3c0,9.6-0.1,19.1-0.1,28.7
		c0.1,12.9,0.3,25.8,0.3,38.7c0.1,15.6,0.1,31.2,0.1,46.8c0,3.4-0.3,6.8-0.4,10.3c0,1.4,0,2.9,0.3,4.3c0.4,2.1,2,3.3,4.2,3.4
		c1.8,0.1,3.5-0.6,5.1-1.2c11.7-4.8,23.4-9.6,35.1-14.4c1.8-0.8,3.6-1.6,5.4-2.4c1.4-0.7,2.7-1.6,3.5-3c1.8-3.3,2.6-6.8,1-10.5
		c-0.3-0.8-0.6-1.5-0.9-2.3c-3-7-6-13.9-9-20.9c-6-14-12-28.1-18.1-42.1c-2.6-6.1-5.3-12.2-7.9-18.3c-1.3-3-2.4-6-3.7-8.9
		c-4.1-9.6-8.3-19.3-12.4-28.9c-0.3-0.7-0.6-1.3-0.9-2C285,412.5,284.7,412.2,284.1,411.5z M590,423.8c0-0.9,0-1.7,0-2.4
		c-0.1-0.7-0.1-1.5-0.3-2.1c-0.1-0.4-0.5-1-0.9-1.1c-0.6-0.2-0.9,0.4-1.1,0.8c-0.2,0.5-0.4,1-0.6,1.4c-2.5,5.7-4.9,11.5-7.4,17.2
		c-0.7,1.5-1.3,3.1-2.2,4.5c-1.3,2.2-2.2,4.5-3,6.8c-0.6,1.7-1.4,3.5-2.2,5.2c-0.7,1.5-1.5,3-2.2,4.5c-2.9,6.4-5.7,12.9-8.6,19.3
		c-3.8,8.4-7.6,16.9-11.4,25.3c-3.1,6.8-6.1,13.6-9.2,20.5c-1.6,3.7-3.2,7.4-4.7,11.2c-0.8,2-0.8,4-0.1,6.1c1.2,3.7,3.5,6.3,7.3,7.4
		c5.2,1.5,10.4,3,15.6,4.5c8,2.3,15.9,4.6,23.9,6.9c0.8,0.2,1.6,0.5,2.4,0.7c2.5,0.4,4.2-0.6,5-3.1c0.3-0.9,0.4-1.8,0.4-2.8
		c0-2.2-0.2-4.4-0.3-6.5c-0.1-2.8-0.3-5.6-0.3-8.4c0-1.5,0.1-2.9,0.1-4.4c-0.1-9.7-0.2-19.3-0.2-29c0-18.1-0.1-36.2,0-54.2
		C590.1,442.5,589,433,590,423.8z M454.1,650.7c2.1-0.2,3.4-1,4.7-1.8c4.1-2.5,8.1-5.1,12.2-7.5c23.2-13.4,46.4-26.8,69.6-40.2
		c13.8-8,27.5-16,41.2-24.1c1.2-0.7,2.4-1.6,3.4-2.6c1.6-1.6,1.3-3.1-0.9-4.1c-1.1-0.5-2.3-0.9-3.5-1.2c-6.4-1.8-12.8-3.6-19.2-5.4
		c-0.4-0.1-0.8-0.2-1.2-0.3c-8.7-2.4-17.5-4.6-26.1-7.1c-5-1.5-9-0.3-12.3,3.5c-0.8,0.9-1.5,1.7-2.2,2.7
		c-5.1,6.8-10.2,13.6-15.4,20.4c-1.9,2.5-3.9,4.9-5.7,7.4c-6.6,8.7-13.1,17.4-19.8,26.1c-7.8,10.2-15.7,20.5-23.5,30.7
		c-0.4,0.6-0.9,1.1-1.2,1.8C454.1,649.3,454.2,649.9,454.1,650.7z M420.7,650.7c0-1.2-0.7-1.9-1.3-2.6c-2.5-3.3-5-6.6-7.6-9.9
		c-9.2-11.8-18.4-23.6-27.6-35.4c-10.4-13.5-20.8-27-31.3-40.5c-0.7-0.9-1.5-1.8-2.4-2.5c-3-2.3-6.2-3.4-9.9-1.9
		c-1.9,0.8-3.9,1.4-5.8,2.2c-8.5,3.4-16.9,6.9-25.4,10.3c-2.9,1.2-5.9,2.1-8.8,3.1c-1.2,0.4-2.4,0.8-3.4,1.5c-3.4,2-3.4,4.3,0,6.2
		c3.1,1.7,6.2,3.4,9.3,5.1c5.2,2.9,10.3,5.8,15.5,8.7c9,5.2,18,10.4,27,15.6c6.5,3.7,12.9,7.5,19.4,11.2c6.3,3.6,12.7,7.1,19,10.7
		c4,2.3,7.9,4.6,11.9,6.9c5.8,3.3,11.5,6.6,17.3,9.9c0.9,0.5,1.9,0.9,2.8,1.3C419.9,650.8,420.3,650.7,420.7,650.7z M556.5,601.8
		c0.8,0.8,1.3,1.6,2,2.2c16.7,17.3,33.3,34.5,50,51.8c0.5,0.5,1,1,1.5,1.6c0.8,0.8,1.7,1.3,2.8,0.7c1.3-0.6,2.3-1.5,2.3-3
		c0.1-1-0.6-1.7-1.2-2.4c-3.3-3.5-6.5-7-9.8-10.4c-5.8-6-11.7-12-17.6-18.1c-7.6-7.8-15.2-15.6-22.8-23.5c-0.6-0.6-1.1-1.5-2.4-1.7
		C559.8,599.8,558.3,600.7,556.5,601.8z M305.1,595.2c-0.8,0.6-1.4,1.2-2,1.7c-16.2,15.1-32.3,30.3-48.5,45.4
		c-0.5,0.5-1.1,1-1.5,1.5c-0.6,0.7-0.7,1.6-0.3,2.3c0.4,0.7,0.9,1.4,1.6,1.9c0.9,0.7,1.9,0.7,2.9,0c0.3-0.2,0.6-0.5,0.9-0.8
		c3.7-3.4,7.4-6.7,11.1-10.1c5.3-5,10.6-10,15.8-15c7.8-7.3,15.6-14.7,23.4-22c0.6-0.6,1.6-1,1.5-2.3
		C308.4,597.1,306.9,596.2,305.1,595.2z M270.8,276.7c-0.7-0.9-1.5-1.7-2.3-2.5c-13-13.4-26.1-26.8-39.1-40.3
		c-9.9-10.2-19.8-20.5-29.7-30.7c-0.6-0.6-1.1-1.2-1.8-1.8c-0.9-0.8-1.8-0.9-2.8-0.2c-0.7,0.5-1.3,1-1.9,1.6c-6.2,6-12.4,12-18.6,18
		c-0.5,0.5-1,1-1.5,1.6c-1,1.3-1,2.1,0.1,3.3c0.5,0.6,1,1.1,1.5,1.6c13.8,14.3,27.5,28.6,41.3,42.9c9.3,9.7,18.6,19.3,27.9,28.9
		c3.2,3.3,3.6,3.3,6.8,0.2c6.1-5.9,12.3-11.8,18.4-17.8c0.7-0.6,1.3-1.3,1.9-2c0.3-0.3,0.4-0.7,0.6-1.2
		C271.3,277.8,271.1,277.2,270.8,276.7z M715.7,235.4c-0.4-0.6-0.9-1.2-1.4-1.7c-6-6.5-12.1-12.9-18.1-19.3
		c-0.6-0.6-1.2-1.1-1.9-1.6c-0.7-0.6-1.6-0.5-2.3,0c-0.7,0.6-1.4,1.2-2.1,1.9c-14.2,13.3-28.4,26.5-42.6,39.8
		c-10.2,9.6-20.5,19.2-30.7,28.8c-2.8,2.7-2.9,3.2-0.2,6c6,6.5,12.1,12.9,18.1,19.3c0.6,0.6,1.2,1.2,1.8,1.7c0.7,0.6,1.5,0.6,2.3,0
		c0.8-0.6,1.6-1.3,2.4-2c13.8-13,27.5-25.9,41.3-38.9c10.4-9.7,20.8-19.5,31.2-29.2c0.7-0.6,1.4-1.3,2-2c0.3-0.4,0.5-0.9,0.7-1.3
		C716.1,236.3,716,235.8,715.7,235.4z M187.2,178c-0.5-7.6-5.2-12.7-12.7-14.1c-3.4-0.6-6.7-0.3-9.9,0.5
		c-11.4,3-19.4,10.4-25.1,20.5c-1.3,2.3-2.1,4.7-2.7,7.3c-0.7,3.1-1,6.2-0.4,9.3c1.2,6.2,4.8,10.2,11,11.9c4.1,1.1,8.3,0.8,12.3-0.5
		c13.3-4.1,21.9-13,26.4-26c0.8-2.3,1-4.7,1-7.1C187.2,179.3,187.3,178.6,187.2,178z M752.6,204.1c-2.9-11-13.7-22.3-24.3-25.9
		c-3.1-1-6.2-1.7-9.5-1.6c-8.5,0.2-14.5,5.9-15.1,14.4c-0.2,3.2,0,6.5,1,9.6c4.1,13,12.7,21.7,25.5,26.2c3.4,1.2,6.9,1.5,10.5,1
		c5.6-0.8,9.6-3.7,11.8-9c1-2.3,1.3-4.8,1.3-7.2C753.7,208.9,753.2,206.5,752.6,204.1z M250.8,316.9c-7.2,8.8-14.1,17.8-19.8,27.6
		c-4.4,7.6-8.1,15.5-10.5,23.9c-1.5,5.4-2.5,10.9-2.3,16.6c0.3,7.2,2.5,13.6,7,19.3c1.3,1.6,2.7,2.2,4.6,1.8
		c2.2-0.4,4.5-0.7,6.8-1.1c3.5-0.6,7-1.2,10.4-1.8c0.8-0.1,1.6-0.3,2.1-1.3c-0.6-0.7-1.2-1.4-1.7-2.1c-6.3-7.6-9.8-16.3-10.1-26.3
		c-0.2-6.5,1.2-12.8,3.6-18.9c2.9-7.5,6.9-14.4,12.1-20.6c1.1-1.3,2.1-2.6,3-4c1.3-2,1.4-4,0.4-6.2c-0.9-2-1.6-4-2.4-6.1
		c-0.3-0.9-0.6-1.7-0.9-2.6C251.9,315.3,251.4,316.2,250.8,316.9z M515.8,285.5c5.4-4.8,11.8-7.7,18.8-9.4c4.7-1.1,9.5-1.2,14.3-0.5
		c6.7,0.9,12.9,3.2,18.9,6.2c5.5,2.8,10.5,6.3,15.2,10.3c1.2,1,2.4,2,3.7,2.8c1.7,1,3.5,1.1,5.4,0.3c2.6-1.1,5.2-2,7.9-3
		c0.4-0.2,1.1-0.1,1.1-1.1c-0.6-0.6-1.3-1.3-2.1-2c-6.5-5.5-13.2-10.8-20.2-15.6c-8.6-5.8-17.7-10.9-27.6-14.2
		c-6.3-2.1-12.8-3.6-19.6-3.4c-7.2,0.2-13.7,2.4-19.4,6.9c-1.4,1.1-2,2.3-1.7,4c0.9,5.9,1.8,11.7,2.8,17.6c0.1,0.7,0.4,1.3,0.8,2.4
		C514.8,286.2,515.4,285.9,515.8,285.5z M628.2,330.3c-2.7,6.9-3.2,5,1.2,11.4c2.4,3.4,4.6,6.9,6.6,10.5c3.6,6.5,6.3,13.4,7.3,20.7
		c1.8,12.7-1.1,24.1-9.4,34c-0.8,1-1.7,1.8-2.5,2.8c-0.3,0.4-0.5,1-0.7,1.5c0.3,0.2,0.3,0.3,0.4,0.3c6.4,1.4,12.8,2.9,19.1,4.3
		c1.4,0.3,2.4-0.3,3.3-1.2c4.3-4.7,7.1-10.2,8.2-16.5c1.2-6.8,0.5-13.5-1-20.1c-1.6-7.1-4.3-13.9-7.5-20.4
		c-2.7-5.4-5.8-10.5-9.1-15.6c-3.3-5-6.6-10.1-10-15c-0.8-1.2-1.4-2.5-3.2-3.4C630,326.1,629.1,328.1,628.2,330.3z M373.2,279.7
		c1.1-5.2,2.1-10.4,3.3-15.6c0.3-1.4,0-2.5-1.1-3.3c-0.3-0.3-0.6-0.5-0.9-0.8c-6.1-5.2-13.2-7.7-21.2-7.8c-7-0.1-13.8,1.2-20.5,3.4
		c-8.6,2.8-16.7,6.9-24.3,11.9c-5.7,3.7-11.2,7.5-16.8,11.3c-1.8,1.2-3.6,2.5-5.4,3.8c-0.6,0.5-1.2,1.1-1.8,1.8
		c0.5,0.4,0.8,0.7,1.2,0.8c3.2,1.3,6.4,2.5,9.5,3.9c1.4,0.6,2.6,0.4,3.8-0.5c1.4-1,2.9-2.1,4.3-3.1c7.3-5.3,15-9.6,23.7-12.3
		c7.3-2.2,14.6-3,22.2-1.5c7.6,1.5,14.3,4.8,20.1,10c0.8,0.7,1.5,1.8,3.1,1.9C372.7,282.1,373,280.9,373.2,279.7z M295.9,325.7
		c0.2-8.8,0.3-17.6,0.5-26.5c0-2.3-0.9-3.9-3.2-4.8c-4.1-1.6-8.1-3.2-12.1-4.9c-1.7-0.7-3-0.3-4.2,0.9c-1.2,1.2-2.4,2.3-3.6,3.4
		c-4.6,4.5-9.2,9-13.9,13.4c-1.5,1.4-2,3.1-1.3,5.1c1.3,3.6,2.6,7.2,3.9,10.8c1,2.7,2.8,4.1,5.9,4.1c8.5,0,17,0.2,25.5,0.4
		c0.6,0,1.2-0.1,2.1-0.1C295.8,326.7,295.9,326.2,295.9,325.7z M589.6,334.4c8.7,0.3,17.4,0.6,26.1,0.9c0.4,0,0.8,0,1.2,0
		c1.8-0.1,3.2-0.9,3.9-2.6c1.8-4.3,3.6-8.6,5.5-12.9c0.5-1.2,0.2-2.2-0.5-3.2c-0.3-0.4-0.7-0.8-1-1.2c-4-4.3-8-8.7-12.1-13
		c-1.3-1.4-2.7-2.9-4.1-4.3c-1.3-1.3-2.8-1.9-4.8-1.2c-3.7,1.4-7.5,2.6-11.2,3.9c-2.6,0.9-4,2.6-4,5.6c-0.2,8.3-0.5,16.6-0.8,24.9
		c0,0.9,0.1,1.8,0.2,2.9C588.8,334.3,589.2,334.4,589.6,334.4z M716.3,224c-1.8-1.5-3.6-3.1-5.3-4.8c-1.7-1.8-3.2-3.8-4.7-5.8
		c-1.5-1.8-2.3-4.2-4.2-5.8c-1.2,0.9-2.6,1.4-3.2,2.8c0.2,1.3,1,1.9,1.7,2.7c1.1,1.2,2.3,2.4,3.5,3.6c3.9,4.2,7.8,8.3,11.7,12.5
		c0.9,1,1.8,2,3.3,2.2c1.3-0.5,2.2-1.4,3.1-2.9C720.1,226.8,718.1,225.5,716.3,224z M169.1,217.6c0.9,1.1,1.6,1.1,2.6,0.2
		c1.1-1,2.1-2,3.1-3c4.7-4.5,9.4-9.1,14.1-13.6c2.4-2.3,2.7-3.6-0.4-5.9c-0.1-0.1-0.4-0.1-0.7-0.2c-1.9,2.5-3.6,5.2-5.7,7.6
		c-2.1,2.4-4.4,4.7-6.9,6.7c-2.4,2-5.1,3.8-7.9,5.7C168,216.1,168.5,216.9,169.1,217.6z M272.8,284c-0.7,0.4-1.3,1-1.9,1.5
		c-5.4,5.2-10.9,10.5-16.3,15.7c-0.5,0.5-1,1-1.4,1.6c-0.5,0.6-0.4,1.4,0.2,2c0.6,0.6,1.3,0.9,1.9,0.4c0.8-0.5,1.5-1.1,2.2-1.7
		c4.7-4.5,9.4-9,14.1-13.5c1-1,2.1-2,3.1-3.1c0.3-0.4,0.5-0.9,0.6-1.1C275.2,284.4,273.8,283.5,272.8,284z M631,310.7
		c-5.6-6.1-11.3-12.1-17-18.1c-0.3-0.3-0.6-0.5-1-0.8c-0.6-0.4-1.4-0.2-2,0.4c-0.6,0.7-0.7,1.5-0.1,2.3c0.8,1.1,1.8,2,2.7,3
		c4.6,4.9,9.2,9.9,13.7,14.8c0.6,0.7,1.3,1.3,2.1,1.9c1,0.7,2.5-0.6,2.4-1.9C631.7,311.9,631.5,311.2,631,310.7z M471.2,489.2
		c-0.3,3.3-0.3,6.7-1.1,9.9c-1.3,5.1-4,9.1-9,11.2c-8.3,3.4-16-0.6-19.1-6.9c-1.4-2.9-2.5-5.9-2.4-9.3c0-0.3,0-0.6,0-0.9
		c-0.9-5.6,0-11.2,1.1-16.7c0.9-4.8,5.1-10.5,12.3-11.1c2.2-0.2,4.4-0.2,6.5,0.3c3.3,0.9,5.9,3,7.8,5.8c1.6,2.5,2.8,5.1,3.1,8.1
		C470.7,482.8,470.9,486,471.2,489.2z M448.4,488.2c0,1.9-0.1,3.7,0,5.6c0.1,2.7,0.8,5.3,1.9,7.8c1.6,3.5,7.2,4.5,9.7,0.3
		c0.9-1.5,1.5-3.2,1.6-5c0.1-1.6,0.3-3.1,0.4-4.7c0.4-4.1,0.2-8.1-0.4-12.1c-0.4-2.3-1.2-4.4-2.4-6.4c-0.6-1.1-1.6-1.7-2.8-1.7
		c-0.8,0-1.7,0-2.5,0.1c-1.8,0.2-3.1,1.1-3.8,2.8c-0.5,1.1-0.9,2.3-1.1,3.5C448.3,481.8,448.3,485,448.4,488.2z M415.5,501.4
		c0.8-0.8,1.6-1.7,2.4-2.4c2.4-2.1,4.9-4.1,7.4-6.2c1.4-1.2,2.9-2.4,4.1-3.8c2.4-2.7,4.1-5.6,4.3-9.4c0.1-3-0.7-5.7-2.3-8.2
		c-1.6-2.5-3.7-4.2-6.5-4.9c-0.8-0.2-1.6-0.5-2.4-0.7c-4.3-0.8-8.5-0.5-12.4,1.5c-4.6,2.4-7.2,6.1-7.1,11.5c0,1.6,0.5,2.9,2.1,3.5
		c1.5,0.6,3.5,0.2,4.6-1.1c0.6-0.7,1-1.6,1.4-2.4c0.4-0.8,0.6-1.8,0.9-2.6c1.5-3.6,7.5-5.4,10.8-2.1c1.9,2,2.6,5.2,1.5,7.8
		c-0.9,2.1-2.4,3.9-4.1,5.4c-1.2,1-2.4,2-3.6,2.9c-3.7,2.8-6.9,6.3-10.2,9.6c-1.5,1.5-2.5,3.3-3.1,5.4c-1,3.3,0.9,5.8,4.3,6
		c0.4,0,0.8,0,1.2,0c4.8,0,9.6,0,14.3,0c2.1,0,4.2,0,6.2,0c1.5,0,2.9-0.3,4.1-1.3c1.7-1.3,2.1-4.8-0.7-5.8c-1.5-0.5-3-0.9-4.5-0.9
		c-3.4,0-6.9,0.1-10.3,0.1c-0.9,0-1.8-0.1-3.2-0.2C415,502,415.2,501.7,415.5,501.4z M368,450.6c-0.6-3.4-1.3-6.7-1.7-10.1
		c-0.4-3-1.4-5.8-2.7-8.5c-1.6-3.3-4.1-5.4-7.4-6.7c-4.2-1.7-10-0.4-12.9,2.9c-2.3,2.6-4.3,5.5-5.6,8.9c-1.8,4.6-1,8.7,1.5,12.8
		c1.1,1.7,2.6,3,4.5,3.7c1.7,0.6,3.1,0.2,4.2-1.2c1.2-1.4,1.3-2.6,0.2-4.3c-0.9-1.4-1.9-2.7-2.8-4.1c-1.4-2.3-1.4-4.8-0.1-7.1
		c2.1-3.8,7-4.5,10-1.5c1.8,1.8,3.2,3.8,3.4,6.5c0.2,1.6,0.5,3.3,0.7,4.9c0.8,5.3,2.2,10.6,3.6,15.8c0.6,2.1,1.8,3.7,3.6,4.9
		c2.1,1.3,4.3,0.9,5.8-1c0.5-0.6,0.9-1.4,1.4-2.1c3.5-5.8,7-11.5,10.4-17.4c0.6-0.9,0.9-2.1,1-3.2c0.2-1.4-0.4-2.6-1.9-3.3
		c-1.5-0.7-2.9-0.7-4,0.4c-0.8,0.8-1.5,1.7-2.1,2.7c-1.7,2.6-3.2,5.3-4.9,8c-0.8,1.2-1.6,2.4-2.6,4
		C368.5,453.7,368.3,452.1,368,450.6z M526.2,476.3c0.5,1.3,0.9,2.4,1.3,3.6c0.5,1.6,0.1,2.6-1.1,3.6c-1.2,0.9-2.5,1.1-3.8,0.3
		c-0.6-0.4-1.1-0.9-1.6-1.4c-0.6-0.6-1-1.3-1.4-1.8c-1.2-0.3-1.8,0.3-2.5,0.7c-1.4,0.8-3,1.4-4.6,1.9c-3,1-5.1-2.5-4.5-4.7
		c0.3-1.3,1-2.3,2.1-2.9c1-0.6,2-1.1,3-1.7c0.6-0.4,1.2-0.8,1.9-1.3c-0.3-0.7-0.4-1.4-0.8-2c-1.7-3.1-3.4-6.2-5.1-9.3
		c-0.6-1.2-1.3-2.4-1.8-3.6c-1.3-3.7,1.6-7,5.5-6.4c3.9,0.6,7.8,1,11.7,1.6c2.9,0.4,5.8,0.8,8.6,1.2c3.4,0.5,6.8,0.9,10.2,1.4
		c1.2,0.2,2.4,0.5,3.6,0.9c3,0.9,3.6,5.9,1,7.4c-1.4,0.8-2.6,1.7-4,2.5c-5.1,2.8-10.2,5.6-15.2,8.5
		C527.9,475.2,527.2,475.7,526.2,476.3z M516.5,458.5c1.5,4.1,4.1,8.6,5.6,10c0.2,0,0.4,0,0.6-0.1c3.9-2.1,7.8-4.2,11.7-6.4
		c0.3-0.2,0.4-0.6,0.8-1.1C528.8,460.2,522.8,459.4,516.5,458.5z M512.7,426.9c-6.8,3.8-13.6,7.6-20.4,11.4
		c-0.8,0.5-1.7,0.9-2.4,1.4c-2,1.6-2.5,4.1-1.1,6.1c1.1,1.6,2.7,2.3,4.6,1.6c1.8-0.7,3.6-1.5,5.3-2.5c6.4-3.5,12.7-7.1,19-10.6
		c3-1.7,6-3.3,8.9-5.1c0.9-0.5,1.7-1.2,2.3-2c1.6-2.3,0.5-4.9-2.3-5.4c-0.5-0.1-1-0.1-1.5-0.2c-4.5-0.7-8.9-1.8-12.6-4.9
		c-1-0.8-2.2-1.5-3.4-2.1c-1.3-0.7-2.7-0.4-3.7,0.7c-1.1,1-1.4,2.3-0.9,3.7c0.4,1.2,1.2,2.1,2.4,2.8c2.3,1.3,4.6,2.7,7.1,4.1
		C513.5,426.4,513.1,426.7,512.7,426.9z M427.6,590.2c-0.5-0.4-0.7-0.6-1-0.7c-4.6-3-6.7-8.1-5.3-13.5c1.1-4.5,3.8-7.8,8.3-9.4
		c1.4-0.5,2.8-0.9,4.2-1.1c3.7-0.4,7.5-0.6,11,0.9c0.9,0.4,1.8,1,2.6,1.6c0.5,0.3,0.9,0.9,1.4,1.2c3.6,2.4,4.4,6.1,4.5,10.1
		c0,2.4-0.7,4.7-2.1,6.7c-0.6,0.9-1.5,1.7-2.4,2.4c-0.6,0.5-1.4,0.9-2.3,1.5c0.4,0.5,0.7,1.1,1.1,1.4c3.5,2.4,4.4,5.8,4,9.8
		c-0.4,3.8-2.6,6.5-5.9,8.3c-3.6,2-7.5,2.2-11.5,1.8c-1.1-0.1-2.2-0.5-3.3-0.7c-4.6-1.1-8.7-5.9-8.2-11.4c0-0.4,0.1-0.8,0.1-1.2
		c0.1-1.9,0.8-3.4,2.2-4.6C426,592.4,426.8,591.5,427.6,590.2z M444.2,579.4c0-0.5,0-1.2-0.2-1.9c-0.5-3-3.1-5.7-6-5.9
		c-2.8-0.2-6.1,0.5-7.5,4.7c-0.2,0.5-0.3,1-0.4,1.5c-0.5,2.3,0,4.3,1.2,6.2c1.3,2,3.2,2.7,5.6,2.8
		C441.4,586.8,444.2,584.2,444.2,579.4z M437.4,604.9c1.2-0.1,2.6-0.1,3.7-1c2.4-1.9,3-5.2,1.3-7.8c-1.4-2.3-5.2-3.4-7.7-2.2
		c-1.4,0.7-2.6,1.7-3.1,3.2C430.2,601.2,432.9,604.9,437.4,604.9z M501.9,367.3c0.6,0.9,1,1.5,1.4,2c0.7,0.9,1.5,1.7,2.2,2.6
		c0.5,0.7,1,1.4,0.7,2.3c-0.5,1-1.5,0.9-2.5,1c-1.2,0-2.3-0.2-3.3-0.9c-1.4-1-2.8-2.1-4.1-3.3c-0.8-0.7-1.4-1.5-2-2.3
		c-1-1.3-2.2-1.8-3.8-1.7c-3.3,0.1-6.6,0.1-10,0.1c-1.6,0-3.1,0-4.7-0.1c-2.9-0.1-5.1-1.5-7.2-3.4c-0.9-0.9-1.5-2-1.4-3.3
		c0.1-1.9,0.2-3.7,0.3-5.6c0.2-4.3,0.5-8.7,0.7-13c0.2-3.3,0.4-3.5,3.6-3.5c1.6,0,3.1,0.5,4.3,1.5c1.4,1.2,2.9,2.4,4.1,3.8
		c4.7,5.2,9.3,10.4,13.8,15.6c1,1.2,2,1.9,3.6,1.9c1.5,0,3,0.6,4.5,1.1c1.3,0.4,2.3,1.4,3.1,2.5c0.6,0.9,0.3,1.9-0.7,2.3
		C504,367,503.2,367.1,501.9,367.3z M476.1,361c2,0,3.8,0,5.5,0c3.6,0,3.6,0,4.9-0.8c-0.9-1.3-8.6-10.1-9.7-11.1
		c-0.1-0.1-0.4,0-0.7-0.1c-0.4,3.6-0.2,7.1-0.4,10.7C475.6,360.2,475.9,360.5,476.1,361z M397,353.3c-1.6-2.1-1.1-4.1,0.2-6.1
		c0.3-0.5,0.7-1.1,1.1-1.5c4.8-5.4,10.8-8.6,18-9.5c1.3-0.2,2.7-0.1,4-0.2c1.8-0.1,3.5,0.4,5.1,1.1c1.9,0.8,2.7,2.7,2.2,4.6
		c-0.3,1-0.7,2-1.2,2.8c-1.7,2.8-4.1,4.9-7,6.5c-1.5,0.8-3.1,1.6-4.6,2.3c0,0.3-0.1,0.4,0,0.5c1.7,2.3,1.1,4.5-0.1,6.7
		c-0.6,1.1-1.2,2.2-2.1,3.1c-3.5,3.6-7.2,6.9-12.2,8.3c-0.8,0.2-1.6,0.6-2.3,0.9c-4.1,1.7-8.3,1.7-12.6,1.5c-0.2,0-0.4,0-0.6-0.1
		c-4.9-0.7-6.1-5.3-3.9-8.6c3.3-5,7.6-8.8,13.2-10.9c0.8-0.3,1.5-0.6,2.3-0.9C396.6,353.8,396.6,353.7,397,353.3z M392.9,369.1
		c0.4,0,0.7-0.1,1-0.1c5-0.7,8.8-3.5,11.8-7.4c0.5-0.6,0.7-1.5,0.9-2.3c0.2-1-0.2-1.9-1.2-2.2c-1-0.3-2-0.5-3-0.4
		c-5.5,0.7-9.2,3.9-12.2,8.3c-0.1,0.2-0.2,0.4-0.3,0.6c-0.5,1.4-0.2,3.2,1.9,3.4C392.2,369,392.6,369.1,392.9,369.1z M407.5,351.3
		c0.9-0.2,1.8-0.4,2.7-0.6c3.2-1,5.7-3,7.6-5.6c0.5-0.7,0.8-1.5,0.4-2.3c-0.5-0.9-1.3-1.3-2.3-1.3c-0.5,0-1,0.1-1.5,0.1
		c-2.9,0.5-5.3,1.7-7.4,3.7c-0.9,0.8-1.7,1.9-2.3,2.9c-0.7,1.1-0.2,2.3,1,2.8C406.2,351.1,406.9,351.2,407.5,351.3z M377.9,354.7
		c-4.3,5.3-8.6,10.7-12.9,16c-0.4,0.5-0.8,1-0.9,1.6c-0.4,1.4,0.5,2.6,1.9,2.5c1.8-0.1,3.5-0.5,4.9-1.7c1.2-1.1,2.5-2.2,3.6-3.4
		c3.6-4.4,7.1-9,10.6-13.4c4.2-5.3,8.5-10.5,12.7-15.8c0.5-0.6,0.9-1.1,1.3-1.8c0.5-0.9,0-1.9-0.9-2.3c-0.7-0.3-1.4-0.3-2.1,0
		c-1.3,0.6-2.7,1.2-3.9,2c-5,3.2-10.3,5.8-16.1,7.4c-1.1,0.3-2.2,0.7-3.2,1.2c-1,0.5-2,1.1-2.9,1.7c-0.4,0.3-0.7,0.7-1,1.2
		c-0.4,0.7-0.4,1.4,0.1,2c0.4,0.6,1,0.8,1.6,0.7c0.6-0.1,1.2-0.2,1.8-0.4c2.5-0.9,5.1-1.8,7.6-2.7c0.6-0.2,1.3-0.4,2.5-0.7
		C380.8,350.9,379.4,352.8,377.9,354.7z M299.1,482c-1.4-0.6-2.9-1.3-4.3-1.7c-2-0.6-3.7,0.2-4.9,1.8c-0.5,0.7-0.9,1.4-1.2,2.2
		c-1.1,2.3-1.5,4.7-1.7,7.2c-0.4,6.7,1.5,12.7,4.5,18.5c0.7,1.3,1.6,2.4,3,3.1c2.3,1.3,4.1,1,5.8-1c0.6-0.7,1.1-1.5,1-2.5
		c-0.1-1.1-0.1-2.3-0.3-3.4c-0.6-2.9-1.3-3.2-4.1-2c-1.8,0.8-2.6,0.6-3.5-1.2c-1.7-3.2-2.1-6.7-1.4-10.3c0.1-0.5,0.3-1,0.6-1.4
		c0.8-1.4,2.1-2,3.7-1.7c0.8,0.1,1.6,0.5,2.4,0.8c1.8,0.7,3.6,1.5,5.5,2.2c1.1,0.4,2.2,0.8,3.3,1c2.3,0.4,3.7-0.3,4.3-2.5
		c0.3-1,0.4-2,0.4-3.1c0-1.7-0.5-3.3-1.2-4.8c-1.1-2.4-2-4.8-3-7.2c-1.5-3.6-2.9-7.1-4.4-10.6c-0.3-0.8-0.7-1.5-1.1-2.2
		c-0.3-0.6-0.8-1-1.5-0.9c-0.7,0.1-1.1,0.6-1.3,1.2c-0.7,2.3-0.5,4.5,0.4,6.7c1.5,3.8,3.1,7.5,4.7,11.2c0.3,0.7,0.5,1.5,0.9,2.7
		C302.9,483.7,301,482.8,299.1,482z M316.3,496.8c-0.7-1.2-2-1.3-2.8-0.3c-0.6,0.7-1,1.5-1.5,2.3c-4.5,7.4-8.1,15.4-12.8,22.7
		c-0.2,0.3-0.3,0.6-0.4,0.8c-0.8,1.7-0.9,3.4-0.6,5.2c0.5,2.5,1.4,3,3.3,2.8c2.3-0.2,4,0.8,5.5,2.3c0.5,0.5,1.1,1,1.6,1.5
		c0.3,0.3,0.6,0.6,0.9,0.9c0.8,0.6,1.9,0.4,2.4-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0.4-2.6-0.2-5-2.3-6.7c-0.8-0.7-1.7-1.2-2.5-1.8
		c-1.5-1-1.5-1.3-0.7-2.7c0.7-1.1,1.5-2.3,2.1-3.4c2.5-4.5,5-9.1,7.5-13.6c0.7-1.2,1.1-2.5,1-3.9
		C317.6,499.6,317.2,498.1,316.3,496.8z M579.2,493.4c-0.4,1.8-0.5,3.1-0.8,4.3c-0.7,2.3-1.3,4.6-2.2,6.8c-0.5,1.2-1.4,2.3-2.4,3.3
		c-1.4,1.5-3.3,1.4-4.7,0c-0.6-0.7-1.3-1.4-1.6-2.3c-0.7-1.5-1.3-3.1-1.7-4.7c-1.3-5.4-1.1-10.9,0-16.4c0.3-1.3,0.5-2.7,0.8-4
		c0.7-3.3,2.3-6.2,4.1-9c0.6-0.9,1.4-1.4,2.5-1.8c1.2-0.4,2.2-0.3,3.2,0.6c1.5,1.4,2.9,3,3.7,5c2.2,5.3,3.7,10.8,4.6,16.5
		c0.5,3.2,0.6,6.4,0.5,9.6c-0.2,6-1.9,11.6-4.7,17c-0.4,0.8-1,1.6-1.6,2.3c-1.7,1.7-4.2,1.4-5.1-0.8c-1-2.5-0.8-5.1,0.6-7.5
		c0.3-0.6,1-0.8,1.6-0.6c0.7,0.2,1.4,0.4,2.1,0.6c1.5-1.3,2.1-3,2.5-4.6c0.6-2.7,0.9-5.6,0.2-8.3
		C580.3,497.5,580.4,495.6,579.2,493.4z M572.7,479.1c-2,1.3-2.9,2.9-3.1,5c-0.1,0.6-0.1,1.3-0.2,1.8c-0.9,3.6,0,7,1.2,10.3
		c0.3,0.8,0.8,1.5,1.3,2.1c0.6,0.6,1.7,0.5,2.2-0.2c0.2-0.3,0.4-0.7,0.6-1.1c2.1-4.4,2.1-9,1-13.6
		C575.3,481.7,574.4,480.2,572.7,479.1z M556.9,502.1c-0.6,2.3-0.8,4.7-0.3,7c0.1,0.6,0.4,1.2,0.7,1.7c0.4,0.7,1.3,0.8,1.9,0.2
		c0.4-0.4,0.8-0.9,1.1-1.5c1-2.1,1.4-4.4,1-6.6c-0.2-1.2-0.3-2.7-1.8-3.6C558.1,499.9,557.3,500.9,556.9,502.1z M515.4,598.3
		c-0.8,4.8-3.5,7.9-6.5,10.8c-2.8,2.7-6,4.8-9.5,6.5c-2.1,1-4.3,1.6-6.6,1.8c-3.7,0.4-4.9-2-4.2-5c0.2-0.9,0.7-1.8,1.2-2.5
		c5-7.8,11.7-13.7,20.6-16.8c4-1.4,8.1-1.9,12.3-1.5c1,0.1,2.1,0.3,3,0.8c1.5,0.7,2.2,2.3,1.8,3.9c-0.5,1.8-1.2,3.5-2.3,5.1
		c-3.5,4.9-7.7,9-13,11.9c-0.9,0.5-1.9,0.8-2.9,1.2c-0.6,0.2-1.2,0.1-1.7-0.4c-0.5-0.6-0.2-1.2,0.1-1.7c1-1.8,2.7-2.9,4.5-3.7
		c2.4-1.1,4.5-2.6,6.3-4.5c1-1,1.6-2.2,1.8-3.6c0.1-0.8-0.2-1.5-1-1.7C518.1,598.6,516.9,598.5,515.4,598.3z M497.3,610.6
		c1.4-0.3,2.8-0.5,4.2-1.1c2.1-0.9,4-2.1,5.7-3.7c0.9-0.9,1.7-2,2.5-3.1c0.5-0.7,1.1-1.7,0.3-2.6c-0.7-0.8-1.7-0.6-2.6-0.4
		c-3.9,1-7.3,2.9-10,5.9c-0.7,0.8-1.2,1.7-1.6,2.6C495.1,609.7,495.8,610.5,497.3,610.6z M527.6,580.3c-4.1,0.5-8.2,0.9-12.4,1.3
		c-2,0.2-3.8,0.9-5.4,2.2c-1.3,1.1-2.3,2.3-2.5,4.3c0.6,0.2,1.2,0.6,1.7,0.6c0.8,0.1,1.7,0,2.5-0.1c5-0.6,10.1-1.2,15.1-1.7
		c1.9-0.2,3.7-0.3,5.6-0.5c2.5-0.2,4.8-1.1,6.7-2.9c1.1-1.1,1.4-2.1,0.9-3.6c-0.8-2.1-0.6-4.3,0.3-6.4c0.4-1,0.8-1.9,1.1-2.9
		c0.1-0.3-0.1-0.9-0.3-1.1c-0.2-0.2-0.8-0.3-1.1-0.1c-2.5,1-4.5,2.5-5.3,5.3c-0.5,1.7-1.2,3.3-1.8,5
		C530.9,579.8,529.2,580.1,527.6,580.3z M359.6,595.9c-6.2-0.4-12.2-1.4-18-3.6c-6.5-2.5-12.1-6.4-16.1-12.2
		c-0.9-1.3-1.6-2.7-2.1-4.1c-1.2-3.2,0.4-5.8,3.8-6.4c3-0.6,6-0.8,9-0.2c1.6,0.3,3.3,0.6,4.9,0.7c4.1,0.3,7.9,1.8,11.6,3.4
		c5.8,2.5,10.6,6.5,14.5,11.5c1.2,1.6,2,3.4,2.1,5.4c0.1,2.5-1,4.3-3.4,4.8C363.8,595.6,361.7,595.7,359.6,595.9z M338.1,576.5
		c-2.5-0.3-4.3-0.2-6.1,0.5c-1.2,0.5-1.5,1.3-0.9,2.5c0.8,1.6,1.9,3,3.5,3.9c1.7,1,3.5,1.9,5.3,2.5c5.2,1.7,10.7,2.3,16.2,2.6
		c0.8,0,1.7,0,2.5-0.2c0.6-0.1,1.2-0.2,1.8-0.5c1-0.4,1.4-1.2,1-2.2c-0.5-1.5-1.6-2.7-2.8-3.6c-2.3-1.8-5-3-7.8-3.6
		C346.3,577.4,341.9,576.8,338.1,576.5z M360.3,605.7c1.3,0.1,2.7,0.2,4,0.4c5.3,0.6,10.5,1.2,15.8,1.8c1,0.1,2.1,0,3.1-0.1
		c0.8-0.1,1.2-0.8,0.9-1.6c-0.5-1.5-1.3-2.8-2.7-3.7c-1.6-1-3.3-1.6-5.2-1.8c-6.7-0.8-13.4-1.5-20.1-2.3c-2.9-0.3-5.8-0.8-8.7-1.1
		c-1.4-0.2-2.9-0.2-4.3-0.2c-1.1,0-1.6,1-1.2,2c0.1,0.4,0.3,0.8,0.6,1.1c0.9,0.8,1.8,1.8,2.9,2.3c6.2,2.9,11.7,6.8,16.7,11.3
		c0.7,0.6,1.4,1.3,2.2,1.8c0.7,0.4,1.5,0.7,2.3,0.9c1.1,0.3,1.8-0.1,2.2-0.7c0.4-0.8,0.1-1.4-0.3-2c-0.5-0.7-1.1-1.3-1.7-1.8
		c-1.9-1.6-3.9-3.1-5.8-4.7c-0.5-0.4-1.1-0.5-1.3-1.3C359.8,605.9,360.1,605.7,360.3,605.7z"
          />
        </g>
      </svg>
    </div>
  );
};

export default LandingPage;
